<template>
  <div v-if="needBan.current || needBan.oponent" class="info-box">
    <template v-if="needBan.current">
      <p class="info-title">
        {{
          $t('matches.hs_pickban_title_text', {
            class: $tc(
              'matches.hs_pickban_title_class',
              pickban.banCount,
            ),
          })
        }}
      </p>
      <pvp-btn :disabled="isBanSending" @click="toggleBanModal">
        {{ $t('matches.hs_pickban_button') }}
      </pvp-btn>
      <pvp-modal v-model="isOpenBanModal" width="530">
        <div slot="modal-title">
          <p class="ban-title">
            {{
              $t('matches.hs_pickban_modal_title_text', {
                class: $tc(
                  'matches.hs_pickban_modal_title_class',
                  pickban.banCount,
                ),
              })
            }}
          </p>
          <span class="ban-subtitle">
            {{
              $t('matches.hs_pickban_modal_subtitle_text', {
                class: $tc(
                  'matches.hs_pickban_modal_subtitle_class',
                  pickban.banCount,
                ),
              })
            }}
          </span>
        </div>
        <form class="ban-pick" @submit.prevent="sendBan">
          <div class="ban-heroes">
            <button
              v-for="(hero, key) in heroesForBan"
              :key="key"
              type="button"
              class="hero-btn"
              :class="{
                active: pickedHeroes.includes(hero.name),
              }"
              @click="togglePickedHeroes(hero.name)"
            >
              <HearthstoneHero :name="hero.cardClass" />
            </button>
          </div>
          <pvp-btn type="submit" :disabled="isBtnDisabled">
            {{ $t('global.confirm') }}
          </pvp-btn>
        </form>
      </pvp-modal>
    </template>
    <span v-else-if="needBan.oponent" class="info-title">
      {{ $t('matches.hs_status_needOpponent') }}
    </span>
  </div>
</template>

<script>
import HearthstoneHero from '@components/GameComponents/HearthstoneHero/HearthstoneHero.vue';

export default {
  components: {
    HearthstoneHero,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isOpenBanModal: false,
    pickedHeroes: [],
    isBanSending: false,
  }),
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('matches', ['getMatchPickBan']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('hearthstone', ['getByDeckstring']),
    pickban() {
      const pickban = this.getMatchPickBan(this.id);
      return {
        isExist: pickban !== undefined,
        oponent:
          pickban && pickban.current === 'team1' ? 'team2' : 'team1',
        ...pickban,
      };
    },
    isBtnDisabled() {
      return (
        this.pickban.banCount !== this.pickedHeroes.length ||
        this.isBanSending
      );
    },
    userStatus() {
      return this.getUserMatchStatus(this.id);
    },
    needBan() {
      if (this.pickban.isExist) {
        const currentTeam = this.pickban[this.pickban.current];
        const oponentTeam = this.pickban[this.pickban.oponent];
        return {
          current:
            this.userStatus.abilities.canPickBan &&
            currentTeam?.needBan,
          oponent: oponentTeam?.needBan,
        };
      }
      return {
        current: false,
        oponent: false,
      };
    },
    heroesForBan() {
      if (!this.pickban.banCount) {
        return [];
      }
      const oponentTeam = this.pickban[this.pickban.oponent];
      return oponentTeam.heroes.map((hero) => {
        const ds = this.getByDeckstring(hero.name);
        if (ds) {
          return {
            cardClass: ds.heroes[0].cardClass,
            name: hero.name,
          };
        }
        return {
          cardClass: hero.name,
          name: hero.name,
        };
      });
    },
  },
  created() {
    this.fetchMatchHSpickStatus(this.id);
  },
  methods: {
    ...mapActions('matches', [
      'fetchMatchHSpickBanConfirm',
      'fetchMatchHSpickStatus',
    ]),
    toggleBanModal() {
      this.isOpenBanModal = !this.isOpenBanModal;
    },
    checkPickedHero(hero) {
      return this.pickedHeroes.includes(hero);
    },
    togglePickedHeroes(hero) {
      if (this.checkPickedHero(hero)) {
        this.pickedHeroes = this.pickedHeroes.filter(
          (pickedHero) => pickedHero !== hero,
        );
      } else if (this.pickedHeroes.length < this.pickban.banCount) {
        this.pickedHeroes.push(hero);
      }
    },
    sendBan() {
      this.isBanSending = true;
      this.isOpenBanModal = false;
      this.fetchMatchHSpickBanConfirm({
        id: this.id,
        ban: this.pickedHeroes,
      }).then(({ success }) => {
        if (!success) {
          this.isBanSending = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  &-title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    & + span {
      margin-top: 12px;
    }
  }

  &-box {
    margin-top: 20px;
    border-radius: 2px;
    background-color: rgba(black, 0.2);
    padding: 24px 30px;
  }
}

.ban {
  &-title {
    line-height: 1;
    margin: 0;
  }

  &-subtitle {
    font-size: 17px;
    font-weight: 400;
  }

  &-heroes {
    .hero-btn {
      display: inline-block;
      vertical-align: top;
      margin: 0 25px 25px;
      padding: 0;
      background: none;
      border: none;

      &.active {
        .hero {
          box-shadow: inset 0 0 0 4px $azure;
        }
      }
    }
  }
}
</style>
