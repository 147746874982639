var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisibleCards)?_c('div',{staticClass:"section"},[_c('span',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('matches.hs_decsTitle')))]),_c('div',{staticClass:"teams-pick"},[(_vm.teamOne)?_c('box',{staticClass:"left"},[_c('span',{staticClass:"team-title"},[_vm._v(_vm._s(_vm.teamOne.name))]),_vm._l((_vm.teamOne.heroes),function(hero,key){return _c('HearthstoneHero',{key:key,attrs:{"disabled":hero.disabled,"name":(hero.ds &&
            hero.ds.heroes[0] &&
            hero.ds.heroes[0].cardClass) ||
          hero.name},on:{"click":function($event){return _vm.showModal(hero.ds)}}})})],2):_vm._e(),(_vm.teamTwo)?_c('box',{staticClass:"right"},[_c('span',{staticClass:"team-title"},[_vm._v(_vm._s(_vm.teamTwo.name))]),_vm._l((_vm.teamTwo.heroes),function(hero,key){return _c('HearthstoneHero',{key:key,attrs:{"disabled":hero.disabled,"name":(hero.ds &&
            hero.ds.heroes[0] &&
            hero.ds.heroes[0].cardClass) ||
          hero.name},on:{"click":function($event){return _vm.showModal(hero.ds)}}})})],2):_vm._e(),(_vm.modalInfo.name)?_c('pvp-modal',{model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('template',{slot:"modal-title"},[_vm._v(_vm._s(_vm.modalInfo.name))]),_c('ul',{staticClass:"list"},_vm._l((_vm.modalInfo.list),function(info,key){return _c('li',{key:key,staticClass:"item"},[_c('template',{slot:"modal-title"},[_vm._v(_vm._s(info.name))]),_c('span',{staticClass:"text cost"},[_vm._v(_vm._s(info.cost))]),_c('span',{staticClass:"text padded",class:info.rarity},[_vm._v(_vm._s(info.name))]),_c('span',{staticClass:"text count"},[_vm._v("x"+_vm._s(info.count))])],2)}),0)],2):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }