<template>
  <div v-if="pickban.isExist" class="info-ban">
    <div class="info-result">
      <span class="info-title">
        {{
          resultInfo.variant === 0
            ? resultInfo.text
            : $t('matches.hs_pickban_judgeTitle')
        }}
      </span>
      <span v-if="resultInfo.variant" class="result-text">{{
        resultInfo.text
      }}</span>
    </div>

    <span
      :class="pickban.team1.needBan ? 'error' : 'success'"
      class="info-team"
    >
      <icon
        :name="
          pickban.team1.needBan ? 'cross-circle' : 'check-circle'
        "
      />
      {{
        $t(
          `matches.hs_pickban_${
            pickban.team1.needBan ? 'not-confirmed' : 'confirmed'
          }`,
        )
      }}
    </span>
    <span
      :class="pickban.team2.needBan ? 'error' : 'success'"
      class="info-team"
    >
      <icon
        :name="
          pickban.team2.needBan ? 'cross-circle' : 'check-circle'
        "
      />
      {{
        $t(
          `matches.hs_pickban_${
            pickban.team2.needBan ? 'not-confirmed' : 'confirmed'
          }`,
        )
      }}
    </span>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isTimerActive: true,
  }),
  computed: {
    ...mapGetters('matches', ['getMatchPickBan']),
    pickban() {
      const pickban = this.getMatchPickBan(this.id);
      return {
        isExist: pickban !== undefined,
        ...pickban,
      };
    },
    resultInfo() {
      let variant = 0;
      const variantsArray = [
        this.$t('matches.hs_status_ready'),
        this.$t('matches.hs_status_needOneTeam'),
        this.$t('matches.hs_status_needBothTeams'),
      ];
      if (this.pickban.team1.needBan) {
        variant += 1;
      }
      if (this.pickban.team2.needBan) {
        variant += 1;
      }
      return {
        variant,
        text: variantsArray[variant],
      };
    },
  },
  methods: {
    hideTimer() {
      this.isTimerActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  &-title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    & + span {
      margin-top: 12px;
    }
  }

  &-ban {
    margin-bottom: 20px;
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 210px 1fr;
      grid-template-areas: '. result .';
      gap: 10px;
    }
    @include min-desktop() {
      grid-template-columns: 1fr 0.8fr 1fr;
      grid-template-areas: '. result .';
      gap: 10px;
    }
    @include max-laptop() {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-result {
    padding-left: 4px;
    padding-right: 4px;
    align-self: center;
    grid-area: result;
    @include max-laptop() {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.result {
  &-text {
    display: block;
    color: rgba(white, 0.5);
    font-size: 13px;
    line-height: 1.5;
  }

  &-countdown {
    color: $azure;
    font-size: 30px;
  }
}

.info-team {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(black, 0.2);
  font-size: 15px;
  @include min-laptop() {
    min-height: 100px;
  }
  @include max-laptop() {
    width: 49%;
  }

  .icon {
    @include min-laptop() {
      margin-right: 6px;
      display: inline-block;
    }
    @include max-laptop() {
      display: none;
    }
  }

  &.error {
    background-color: rgba($burnt-red, 0.25);

    .icon {
      color: $burnt-red;
    }
  }

  &.success {
    background-color: rgba($tree-green, 0.25);

    .icon {
      color: $dark-pastel-green;
    }
  }
}
</style>
