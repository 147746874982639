<template>
  <div v-if="isVisibleCards" class="section">
    <span class="section-title">{{
      $t('matches.hs_decsTitle')
    }}</span>
    <div class="teams-pick">
      <box v-if="teamOne" class="left">
        <span class="team-title">{{ teamOne.name }}</span>
        <HearthstoneHero
          v-for="(hero, key) in teamOne.heroes"
          :key="key"
          :disabled="hero.disabled"
          :name="
            (hero.ds &&
              hero.ds.heroes[0] &&
              hero.ds.heroes[0].cardClass) ||
            hero.name
          "
          @click="showModal(hero.ds)"
        />
      </box>
      <box v-if="teamTwo" class="right">
        <span class="team-title">{{ teamTwo.name }}</span>
        <HearthstoneHero
          v-for="(hero, key) in teamTwo.heroes"
          :key="key"
          :disabled="hero.disabled"
          :name="
            (hero.ds &&
              hero.ds.heroes[0] &&
              hero.ds.heroes[0].cardClass) ||
            hero.name
          "
          @click="showModal(hero.ds)"
        />
      </box>
      <pvp-modal v-if="modalInfo.name" v-model="isModalVisible">
        <template slot="modal-title">{{ modalInfo.name }}</template>
        <ul class="list">
          <li
            v-for="(info, key) in modalInfo.list"
            :key="key"
            class="item"
          >
            <template slot="modal-title">{{ info.name }}</template>
            <span class="text cost">{{ info.cost }}</span>
            <span class="text padded" :class="info.rarity">{{
              info.name
            }}</span>
            <span class="text count">x{{ info.count }}</span>
          </li>
        </ul>
      </pvp-modal>
    </div>
  </div>
</template>

<script>
import HearthstoneHero from '@components/GameComponents/HearthstoneHero/HearthstoneHero.vue';

export default {
  components: {
    HearthstoneHero,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isModalVisible: false,
    modalInfo: {},
  }),
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('matches', ['getMatchPickBan', 'getMatch']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('hearthstone', ['getByDeckstring']),
    pickban() {
      const pickban = this.getMatchPickBan(this.id);
      return {
        isExist: pickban !== undefined,
        ...pickban,
      };
    },
    userStatus() {
      return this.getUserMatchStatus(this.id);
    },
    match() {
      return this.getMatch(this.id);
    },
    teamOne() {
      return this.prepareTeamData(1);
    },
    teamTwo() {
      return this.prepareTeamData(2);
    },
    isVisibleCards() {
      return (
        this.pickban.isExist &&
        (this.teamOne.heroes.length || this.teamTwo.heroes.length)
      );
    },
  },
  methods: {
    showModal(info) {
      if (info === undefined) {
        return;
      }
      this.modalInfo = {
        name: info.heroes[0].className,
        list: info.cards,
      };
      this.isModalVisible = true;
    },
    prepareTeamData(side) {
      let heroes = [];
      const team = this.getTeam(
        this.match.teams.find((team) => team.side === side).hash,
      );

      if (this.pickban.isExist) {
        const canViewDisabled =
          this.userStatus.roles.judge ||
          this.pickban.current !== `team${side}` ||
          !this.pickban[`team${side}`].needBan;

        heroes = this.pickban[`team${side}`].heroes.map((hero) => ({
          ...hero,
          ds: this.getByDeckstring(hero.name),
          disabled: canViewDisabled ? hero.disabled : false,
        }));
      }
      return { ...team, heroes };
    },
  },
};
</script>

<style lang="scss" scoped>
.teams-pick {
  @include min-laptop() {
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.team-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.box {
  padding: 20px;
  @include max-laptop() {
    margin-top: 20px;
  }

  &.left {
    grid-area: left;
  }

  &.right {
    grid-area: right;
  }
}

.hero {
  display: inline-block;
  vertical-align: top;
  margin: 12px 12px 0 0;
  font-size: 5.3px;
}

.list {
  text-decoration: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include min-tablet() {
    column-count: 2;
    column-gap: 24px;
  }

  .item {
    display: flex;
    justify-content: space-between;

    .text {
      display: block;

      &.cost {
        color: $azure;
      }

      &.padded {
        flex-grow: 1;
        padding: 0 12px;
      }

      &.common {
        color: #82c406;
      }

      &.rare {
        color: $azure;
      }

      &.epic {
        color: $orangey-red;
      }

      &.legendary {
        color: $gold-two;
      }
    }
  }
}
</style>
